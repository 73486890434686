<template>
  <div align="center">
    <br>
    <div class="card col-md-7 shadow-lg rounded" align="left">
      <div class="card-body">
        <div class="card-title">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Id (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item._id" required disabled="true">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Número (*):</label>
                <input type="number" class="form-control col-md-8" v-model="item.cmp_nmro" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Nombre (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.cmp_nmbre" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Ciudad (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.cmp_ciudad" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Dirección (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.cmp_direccion" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Teléfono (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.cmp_telefono" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Subdominio (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.cmp_subdominio" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Activar firmas:</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.cmp_activar_firmas">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Activar Ocr:</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.cmp_activar_ocr">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Activar Correo electrónico:</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.cmp_activar_email">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Activar procesos:</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.cmp_activar_procesos">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Activar reclamaciones:</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.cmp_activar_reclamaciones">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Activar Pqrs Contraloría:</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.cmp_activar_pqrs">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Fecha registro:</label>
                <input type="text" class="form-control col-md-8" v-model="fechareg" disabled>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Inactiva:</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.cmp_inactiva">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Descripción:</label>
                <textarea rows="2" class="form-control col-md-8" v-model="item.cmp_descripcion" />                
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Logo:</label>
                <div class="col-md-8">
                  <img v-bind:src="logo" alt="Logo" style="border: 1px solid #E1E1DE">
                  <br>
                  <div class="custom-file col-md-8">
                    <input type="file" id="files" ref="files" v-on:change="addFiles" class="custom-file-input">
                    <label class="custom-file-label" for="files">Seleccionar archivo</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-6">
              <button type="submit" class="btn btn-primary">{{ buttontext }}</button>
            </div>
            <div class="col-6">
              <button type="text" class="btn btn-secondary" v-on:click="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import moment from 'moment';

  export default {
    data(){
      return{
        add: true,
        title: '',
        item: {},
        message: '',
        buttontext: '',
        ready: false,
        files: [],
        uploaded: false,
        logoChanged: false,
        logoSaved: false,
        logo: '',
        fechareg: null
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
        this.add = this.$route.params.add;
        if (this.add){
          this.title = 'Crear una Compañía';
          this.buttontext = 'Agregar Compañía';
        } else {
          this.title = 'Editar una Compañía';
          this.buttontext = 'Actualizar Companía';
          this.getItem();
        }
    },
    methods: {
      saveItem(){
        var n = 0;
        for (var i = 0; i < this.files.length; i++){
          if (this.files[i].uploaded == '0'){
            n++;
          }
        }
        if (n < this.files.length){
          alert('Espere por favor, no se han cargado todos los archivos adjuntos.');
          return;
        }

        if (this.add){
          this.message = 'Agregando registro';
          let uri = '/cmps/add';
          //alert(this.item.dcm_fcharad);
          this.axios.post(uri, this.item)
          .then(response => {
            console.log(response);
            //toastr.success(response.data.item, 'Response');
            var src = './../repos/temp/logos';
            var dst = './../repos/' + String(response.data._id) + '/logos';
            let uri2 = '/api/files/save';
            this.axios.post(uri2, { 'src': src, 'dst': dst, adjuntos: this.files })
            .then((response2) => {
              this.message = 'Archivos guardados';
              this.$router.replace({ name: 'DisplayCompania'});
            }).catch((err) => {
              this.message = 'Error al guardar la imagen del logo.';
            });

          })
          .catch(err => {
            console.log(err);
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/cmps/update/' + this.$route.params.id;
          this.axios.post(uri, this.item)
          .then((response) => {
            var src = './../repos/temp/logos';
            var dst = './../repos/' + String(this.item._id) + '/logos';

            if (this.logoChanged){
              let uri2 = '/api/files/save';
              this.axios.post(uri2, { 'src': src, 'dst': dst, adjuntos: this.files })
              .then((response2) => {
                this.message = 'Archivos guardados';
                this.$router.replace({ name: 'DisplayCompania'});
              }).catch((err) => {
                this.message = 'Error al guardar la imagen del logo.';
              });
            } else {
              this.message = 'Datos guardados';
              this.$router.replace({ name: 'DisplayCompania'});
            }
          })
          .catch((err) => {
            this.message = 'Error al guardar los datos.';
          });
        }
      },
      getItem(){
        let uri = '/cmps/edit/' + this.$route.params.id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;
          if (this.item.cmp_fechareg){
            this.fechareg = moment(this.item.cmp_fechareg).format('DD/MMM/YYYY hh:mm A');
          }

          if (this.item.cmp_logo){
            this.files.push({});
            this.files[0].adj_nmbre = this.item.cmp_logo;
            this.files[0].temp = false;
            this.files[0].uploaded = '0';
            //this.logoSaved = true;
            //this.logoChanged = true;
            var path = './../repos/' + String(this.item._id) + '/logos';
            this.downloadNewLogo(path, this.item.cmp_logo);
          }
        });
      },
      cancel(){
        this.$router.replace({name: 'DisplayCompania'});
      },
      addFiles: function(e) {
        var f = e.target.files;
        var data;
        var n;
        var m; //Indice de respuesta
        //var path = './src/public/images/logos/temp';
        var path = './../repos/temp/logos';

        if (f){
          this.ready = false;

          this.files = [];
          this.files.push({});
          this.files[0]['adj_nmbre'] = f[0].name;
          this.files[0]['uploaded'] = '1';
          this.files[0]['temp'] = true;
          this.$set(this.files, 0, this.files[0]);

          const data = new FormData();
          // Append file
          data.append('file', f[0]);
          data.append('index', 0);
          data.append('path', path);

          //Make the request to the POST /multiple-files URL
          this.axios.post('/api/files/upload',
            data,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }
          )
          //.then(wait(5000))
          .then((response) => {
            let m = response.data.index;
            this.files[m]['uploaded'] = '0';
            this.$set(this.files, m, this.files[m]);
            this.item.cmp_logo = f[0].name;
            this.logoSaved = false;
            this.logoChanged = true;
            this.downloadNewLogo(path, this.item.cmp_logo);

            //console.log('SUCCESS!!');
          })
          .catch((err) => {
            let m = response.data.index;
            this.files[m]['uploaded'] = '2';
            this.$set(this.files, m, this.files[m]);
            //console.log('FAILURE!!');
          });
        }

        this.ready = true;
      },
      downloadNewLogo(path, filename){
        this.axios({
          url: '/api/files/download',
          method: 'POST',
          data: { 'path': path, 'filename': filename },
          responseType: 'blob'
        }).then((response) => {
            this.logo = window.URL.createObjectURL(new Blob([response.data]));
        });
      }
    }
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
