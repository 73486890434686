<template>
  <div class="col-md-12 mx-auto">
        <div class="card">
            <div id="report" class="card-body">
                <div class="container-fluid">
                  <div style="width: 80px;">
                    <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
                  </div>
                  <div class="headerreport">
                    <div class="row">
                      {{ companydata.cmp_nmbre }}
                    </div>
                    <div class="row">
                      {{ fecha }}
                    </div>
                  </div>
                </div>
                <br>
                <div class="card-title">
                  <h1 class="titlereport">Estadística de Solicitudes por Proceso</h1>
                  <h5 v-if="$route.params.dep_nmbre" class="subtitle">Dependencia: {{ $route.params.dep_nmbre }}</h5>
                  <h5 class="subtitle">{{ rango }}</h5>
                </div>
                <div v-if="showProgress" style="text-align:center">
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                    <table id="reporteEstadisticaProcesos" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                        <thead>
                            <tr>
                                <th>Proceso</th>
                                <th>Respondido</th>
                                <th>Pendiente respuesta</th>
                                <th>Total</th>
                                <th>%</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" class="rowstyle">
                              <td>{{ item.prc_nmbre }}</td>
                              <td class="text-right">{{ item.true }}</td>
                              <td class="text-right">{{ item.false }}</td>
                              <td class="text-right">{{ item.total }}</td>
                              <td class="text-right">{{ item.porcentaje }}</td>
                            </tr>
                        </tbody>
                        <tr style="font-weight:bold">
                            <td>TOTAL</td>
                            <td class="text-right">{{ suma_true }}</td>
                            <td class="text-right">{{ suma_false }}</td>
                            <td class="text-right">{{ suma_total }}</td>
                            <td class="text-right">{{ suma_porcentaje }}</td>
                        </tr>
                    </table>
                </div>
                <div class="row justify-content-center">
                  <div id="chartContainer" class="col-sm-6">
                    <pie-chart :chart-id="'myChart'" :chart-data="datacollection" :options="options"></pie-chart>
                  </div>
                </div>
                <!--
                <div>
                  <canvas responsive="true" id="myChart" width="400" height="400"></canvas>
                </div>
                -->
            </div>
        </div>
        <br>
        <div class="row justify-content-around" align="center">
          <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
          <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
        </div>
    </div>
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import print from 'print-js';
import XLSX from 'xlsx';
import PieChart from './PieChart.js';

//import Chart from 'chart.js';

export default {
  components: { 
    //VProgressCircular,
    PieChart
    },
  data(){
    return{
      message: '',
      items: [],
      procesos: [],
      debug: null,
      fecha: null,
      rango: '',
      dcm_fcharadini: '',
      dcm_fcharadfin: '',
      showProgress: false,
      datacollection: {},
      options: {},
      viewButton: '',
      suma: 0,
      suma_porcentaje: 0
    }
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: async function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    await this.fetchProcesos();
    this.debug = this.$route.params;
    this.dcm_fcharadini = this.$route.params.query.dcm_fcharadini;
    this.dcm_fcharadfin = this.$route.params.query.dcm_fcharadfin;

    this.rango = "Desde: " + moment(this.dcm_fcharadini).format('DD/MMM/YYYY') + " --- Hasta: " + moment(this.dcm_fcharadfin).format('DD/MMM/YYYY');
    this.searchItems(this.$route.params.query);
  },
  mounted: function() {
  },
  methods: {
    fillData () {
      var labels = this.items.map(i => (i.prc_nmbre));
      var data = this.items.map(i => (i.total));

      var colors = [];

      var dynamicColors = () => {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
      };

      for (var i in data) {
        colors.push(dynamicColors());
      }      

      this.datacollection = {
        labels: labels,
        datasets: [
          {
            label: 'Cantidad',
            data: data,
            backgroundColor: colors,
            borderColor: 'rgba(200, 200, 200, 0.75)',
            borderWidth: 1
          }
        ]
      };

      this.options = {
        scales: {

        },
        responsive: true,
        title: {
          display: true,
          text: 'Número de solicitudes'
        },
        tooltips: {
          mode: 'index',
          intersect: true
        },
        annotation: {
          annotations: [{

          }]
        }
      };
    },
    async fetchProcesos(){
      let uri = '/procesos/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.procesos = response.data;
      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = String(this.$store.state.company);
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/estadprocesos';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;

          this.suma_true = 0;
          this.suma_false = 0;
          this.suma_total = 0;
          this.suma_porcentaje = 0;
          for (var i = 0; i < list.length; i++){
            list[i].prc_nmbre = 'No clasificado';
            for (var j = 0; j < this.procesos.length; j++){
              if (list[i].prc_id == this.procesos[j]._id){
                list[i].prc_nmbre = this.procesos[j].prc_nmbre;
                j = this.procesos.length;
              }
            }

            list[i].true = list[i].true || 0;
            list[i].false = list[i].false || 0;

            list[i].total = list[i].true + list[i].false;
            this.suma_true += list[i].true;
            this.suma_false += list[i].false;
            this.suma_total += list[i].total;
          }

          for (var i = 0; i < list.length; i++){
            let p = list[i].total / this.suma_total * 100;
            this.suma_porcentaje += p;
            list[i].porcentaje = p.toFixed(2).toString() + '%';
          }

          this.suma_porcentaje = this.suma_porcentaje.toFixed(2).toString() + '%';

          this.items = list;
          this.fillData();

          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Print');
      p.document.open();
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      var chartContainer = p.document.getElementById('chartContainer');
      var myChart = document.getElementById('myChart');
      p.document.getElementById('myChart').remove();
      const image = p.document.createElement('img');
      image.src = myChart.toDataURL();
      chartContainer.appendChild(image);
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus()
              p.print();
              p.close();
      }, 1000);        
    },
    exportReport(){
      var tableId = 'reporteEstadisticaProcesos';

      var report = [];
      var suma = 0;
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.prc_nmbre = this.items[i].prc_nmbre;
        item.true = this.items[i].true;
        item.false = this.items[i].false;
        item.total = this.items[i].total;
        item.porcentaje = this.items[i].porcentaje;
        
        report.push(item);
      }
      
      let item = {};
      item.prc_nmbre = 'TOTAL';
      item.true = this.suma_true;
      item.false = this.suma_false;
      item.total = this.suma_total;
      item.porcentaje = this.suma_porcentaje;
      report.push(item);

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Estadística de Solicitudes por Proceso"],
        [this.rango],
        [],
        ["Proceso", "Respondido", "Pendiente respuesta", "Porcentaje"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>

  @import '../../../public/css/report.css';

  .small {
      width: 500px;
      margin: 50px auto;
    }  
</style>
