import Vue from 'vue'
import VueRouter from 'vue-router'

//import Signin from '../components/login/Signin.vue';
const Signin = () => import('../components/login/Signin.vue');
const Ldap = () => import('../components/login/Ldap.vue');

const Pqrs = () => import('../components/pqrs/Pqrs.vue');
const AcceptedPqrs = () => import('../components/pqrs/AcceptedPqrs.vue');
const ErrorPqrs = () => import('../components/pqrs/ErrorPqrs.vue');
const FindPqrs = () => import('../components/pqrs/FindPqrs.vue');
const NewPqrs = () => import('../components/pqrs/NewPqrs.vue');
const ResultPqrs = () => import('../components/pqrs/ResultPqrs.vue');

const Signup = () => import('../components/login/Signup.vue');
const Success = () => import('../components/login/Success.vue');
const EmailCheck = () => import('../components/login/EmailCheck.vue');
const PwdReset = () => import('../components/login/PwdReset.vue');
const PwdEmail = () => import('../components/login/PwdEmail.vue');
const PwdChange = () => import('../components/login/PwdChange.vue');
const PwdSuccess = () => import('../components/login/PwdSuccess.vue');
const PwdNotFound = () => import('../components/login/PwdNotFound.vue');
const NotFound = () => import('../components/login/NotFound.vue');
const IdleLogout = () => import('../components/login/IdleLogout.vue');

const Dashboard = () => import('../components/dashboard/Dashboard.vue');
const Home = () => import('../components/home/Home.vue');
const TerminosYCondiciones = () => import('../components/home/TerminosYCondiciones.vue');

const CreateDoc = () => import('../components/doc/CreateDoc.vue');
const DisplayDoc = () => import('../components/doc/DisplayDoc.vue');
const AdvancedSearchDoc = () => import('../components/doc/AdvancedSearchDoc.vue');
const ViewPdf = () => import('../components/doc/ViewPdf.vue');
const DisplayDocNotInTablas = () => import('../components/doc/DisplayDocNotInTablas.vue');
const viewHistory = () => import('../components/doc/viewHistory.vue');

const CreateTpoarch = () => import('../components/tpoarch/CreateTpoarch.vue');
const DisplayTpoarch = () => import('../components/tpoarch/DisplayTpoarch.vue');

const CreateDependencia = () => import('../components/dependencia/CreateDependencia.vue');
const DisplayDependencia = () => import('../components/dependencia/DisplayDependencia.vue');

const CreateIndice = () => import('../components/indice/CreateIndice.vue');
const DisplayIndice = () => import('../components/indice/DisplayIndice.vue');

const CreateSerie = () => import('../components/serie/CreateSerie.vue');
const DisplaySerie = () => import('../components/serie/DisplaySerie.vue');

const CreateTpocor = () => import('../components/tpocor/CreateTpocor.vue');
const DisplayTpocor = () => import('../components/tpocor/DisplayTpocor.vue');

const CreateCompania = () => import('../components/compania/CreateCompania.vue');
const DisplayCompania = () => import('../components/compania/DisplayCompania.vue');

const CreateTpodoc = () => import('../components/tpodoc/CreateTpodoc.vue');
const DisplayTpodoc = () => import('../components/tpodoc/DisplayTpodoc.vue');

const CreateTpotabla = () => import('../components/tpotabla/CreateTpotabla.vue');
const DisplayTpotabla = () => import('../components/tpotabla/DisplayTpotabla.vue');

const CreateEstado = () => import('../components/estado/CreateEstado.vue');
const DisplayEstado = () => import('../components/estado/DisplayEstado.vue');

const CreateUsuario = () => import('../components/usuario/CreateUsuario.vue');
const DisplayUsuario = () => import('../components/usuario/DisplayUsuario.vue');
const CreateUsuarioGlobal = () => import('../components/usuario/CreateUsuarioGlobal.vue');
const DisplayUsuarioGlobal = () => import('../components/usuario/DisplayUsuarioGlobal.vue');
const Profile = () => import('../components/usuario/Profile.vue');
const Security = () => import('../components/usuario/Security.vue');

const CreateTpousu = () => import('../components/tpousu/CreateTpousu.vue');
const DisplayTpousu = () => import('../components/tpousu/DisplayTpousu.vue');

const CreateTablaVersion = () => import('../components/tablaversion/CreateTablaVersion.vue');
const DisplayTablaVersion = () => import('../components/tablaversion/DisplayTablaVersion.vue');

const CreateTabla = () => import('../components/tabla/CreateTabla.vue');
const DisplayTabla = () => import('../components/tabla/DisplayTabla.vue');

const CreateUsuxdep = () => import('../components/usuxdep/CreateUsuxdep.vue');
const DisplayUsuxdep = () => import('../components/usuxdep/DisplayUsuxdep.vue');

const CreateUsuxrad = () => import('../components/usuxrad/CreateUsuxrad.vue');
const DisplayUsuxrad = () => import('../components/usuxrad/DisplayUsuxrad.vue');
const CopyUsuxrad = () => import('../components/usuxrad/CopyUsuxrad.vue');

const CreateSala = () => import('../components/sala/CreateSala.vue');
const DisplaySala = () => import('../components/sala/DisplaySala.vue');

const CreateUsuxsal = () => import('../components/usuxsal/CreateUsuxsal.vue');
const DisplayUsuxsal = () => import('../components/usuxsal/DisplayUsuxsal.vue');

const CreateMenu = () => import('../components/menu/CreateMenu.vue');
const DisplayMenu = () => import('../components/menu/DisplayMenu.vue');

const CreateMenupf = () => import('../components/menupf/CreateMenupf.vue');
const DisplayMenupf = () => import('../components/menupf/DisplayMenupf.vue');

const MigrarDatos = () => import('../components/migrar/MigrarDatos.vue');

const Export = () => import('../components/export/Export.vue');

const CreateCounter = () => import('../components/counter/CreateCounter.vue');
const DisplayCounter = () => import('../components/counter/DisplayCounter.vue');

//import Pdf from '../components/pdf/App.vue';

const FormControlEntrega = () => import('../components/reports/FormControlEntrega.vue');
const ReportControlEntrega = () => import('../components/reports/ReportControlEntrega.vue');

const FormPendienteRespuesta = () => import('../components/reports/FormPendienteRespuesta.vue');
const ReportPendienteRespuesta = () => import('../components/reports/ReportPendienteRespuesta.vue');

const FormEstadisticaRadicada = () => import('../components/reports/FormEstadisticaRadicada.vue');
const ReportEstadisticaRadicada = () => import('../components/reports/ReportEstadisticaRadicada.vue');

const FormEstadisticaRadUsuario = () => import('../components/reports/FormEstadisticaRadUsuario.vue');
const ReportEstadisticaRadUsuario = () => import('../components/reports/ReportEstadisticaRadUsuario.vue');

const FormInventarioDocs = () => import('../components/reports/FormInventarioDocs.vue');
const ReportInventarioDocs = () => import('../components/reports/ReportInventarioDocs.vue');

const FormFuid = () => import('../components/reports/FormFuid.vue');
const ReportFuid = () => import('../components/reports/ReportFuid.vue');

const FormEtiquetaCaja = () => import('../components/reports/FormEtiquetaCaja.vue');
const ReportEtiquetaCaja = () => import('../components/reports/ReportEtiquetaCaja.vue');

const FormContenidoCaja = () => import('../components/reports/FormContenidoCaja.vue');
const ReportContenidoCaja = () => import('../components/reports/ReportContenidoCaja.vue');

const FormContenidoLegajo = () => import('../components/reports/FormContenidoLegajo.vue');
const ReportContenidoLegajo = () => import('../components/reports/ReportContenidoLegajo.vue');

const FormDocsSinArchivar = () => import('../components/reports/FormDocsSinArchivar.vue');
const ReportDocsSinArchivar = () => import('../components/reports/ReportDocsSinArchivar.vue');

const FormTransferencias = () => import('../components/reports/FormTransferencias.vue');
const ReportTransferencias = () => import('../components/reports/ReportTransferencias.vue');

const FormDocsRadicados = () => import('../components/reports/FormDocsRadicados.vue');
const ReportDocsRadicados = () => import('../components/reports/ReportDocsRadicados.vue');

const FormSegDocs = () => import('../components/reports/FormSegDocs.vue');
const ReportSegDocs = () => import('../components/reports/ReportSegDocs.vue');

const FormDocsRadUsuario = () => import('../components/reports/FormDocsRadUsuario.vue');
const ReportDocsRadUsuario = () => import('../components/reports/ReportDocsRadUsuario.vue');

const FormSinAdjuntos = () => import('../components/reports/FormSinAdjuntos.vue');
const ReportSinAdjuntos = () => import('../components/reports/ReportSinAdjuntos.vue');

const FormAdjuntosFaltantes = () => import('../components/reports/FormAdjuntosFaltantes.vue');
const ReportAdjuntosFaltantes = () => import('../components/reports/ReportAdjuntosFaltantes.vue');

const FormTamanoRepos = () => import('../components/reports/FormTamanoRepos.vue');
const ReportTamanoRepos = () => import('../components/reports/ReportTamanoRepos.vue');

const FormPqrs = () => import('../components/reports/FormPqrs.vue');
const ReportPqrs = () => import('../components/reports/ReportPqrs.vue');

const FormSolicitudesAsignadas = () => import('../components/reports/FormSolicitudesAsignadas.vue');
const ReportSolicitudesAsignadas = () => import('../components/reports/ReportSolicitudesAsignadas.vue');

const FormEstadisticaPqrs = () => import('../components/reports/FormEstadisticaPqrs.vue');
const ReportEstadisticaPqrs = () => import('../components/reports/ReportEstadisticaPqrs.vue');

const FormEstadisticaPqrsTotal = () => import('../components/reports/FormEstadisticaPqrsTotal.vue');
const ReportEstadisticaPqrsTotal = () => import('../components/reports/ReportEstadisticaPqrsTotal.vue');

const FormEstadisticaProcesos = () => import('../components/reports/FormEstadisticaProcesos.vue');
const ReportEstadisticaProcesos = () => import('../components/reports/ReportEstadisticaProcesos.vue');

const FormEstadisticaDocsTramitados = () => import('../components/reports/FormEstadisticaDocsTramitados.vue');
const ReportEstadisticaDocsTramitados = () => import('../components/reports/ReportEstadisticaDocsTramitados.vue');

const FormTiemposRespuesta = () => import('../components/reports/FormTiemposRespuesta.vue');
const ReportTiemposRespuesta = () => import('../components/reports/ReportTiemposRespuesta.vue');

const CreateUconserv = () => import('../components/uconserv/CreateUconserv.vue');
const DisplayUconserv = () => import('../components/uconserv/DisplayUconserv.vue');

const CreateUsuxcmp = () => import('../components/usuxcmp/CreateUsuxcmp.vue');
const DisplayUsuxcmp = () => import('../components/usuxcmp/DisplayUsuxcmp.vue');

const Help = () => import('../components/help/Help.vue');
const Support = () => import('../components/help/Support.vue');
const About = () => import('../components/help/About.vue');

const CreateTpopqrs = () => import('../components/tpopqrs/CreateTpopqrs.vue');
const DisplayTpopqrs = () => import('../components/tpopqrs/DisplayTpopqrs.vue');

const Compania = () => import('../components/settings/Compania.vue');
const SettingPqrs = () => import('../components/settings/SettingPqrs.vue');
const SettingEmail = () => import('../components/settings/SettingEmail.vue');
const SettingOptions = () => import('../components/settings/SettingOptions.vue');
const SettingBackup = () => import('../components/settings/SettingBackup.vue');

const CreateEntidad = () => import('../components/entidad/CreateEntidad.vue');
const DisplayEntidad = () => import('../components/entidad/DisplayEntidad.vue');

const CreateTpoid = () => import('../components/tpoid/CreateTpoid.vue');
const DisplayTpoid = () => import('../components/tpoid/DisplayTpoid.vue');

const CreateTposolic = () => import('../components/tposolic/CreateTposolic.vue');
const DisplayTposolic = () => import('../components/tposolic/DisplayTposolic.vue');

const CreateMedioresp = () => import('../components/medioresp/CreateMedioresp.vue');
const DisplayMedioresp = () => import('../components/medioresp/DisplayMedioresp.vue');

const CreateLog = () => import('../components/log/CreateLog.vue');
const DisplayLog = () => import('../components/log/DisplayLog.vue');

const CreateLogGlobal = () => import('../components/log/CreateLogGlobal.vue');
const DisplayLogGlobal = () => import('../components/log/DisplayLogGlobal.vue');

const DisplayActivity = () => import('../components/log/DisplayActivity.vue');
const DetailActivity = () => import('../components/log/DetailActivity.vue');

const CreatePlantilla = () => import('../components/plantilla/CreatePlantilla.vue');
const DisplayPlantilla = () => import('../components/plantilla/DisplayPlantilla.vue');

const DisplayInbox = () => import('../components/inbox/DisplayInbox.vue');
const CreateInbox = () => import('../components/inbox/CreateInbox.vue');

const DisplayInboxUser = () => import('../components/inbox/DisplayInboxUser.vue');
const CreateInboxUser = () => import('../components/inbox/CreateInboxUser.vue');

const CreatePrestamo = () => import('../components/prestamo/CreatePrestamo.vue');
const DisplayPrestamo = () => import('../components/prestamo/DisplayPrestamo.vue');

const CreateDianolGlobal = () => import('../components/dianol/CreateDianolGlobal.vue');
const DisplayDianolGlobal = () => import('../components/dianol/DisplayDianolGlobal.vue');

const CreateDianol = () => import('../components/dianol/CreateDianol.vue');
const DisplayDianol = () => import('../components/dianol/DisplayDianol.vue');

const CreateFirma = () => import('../components/firma/CreateFirma.vue');
const DisplayFirma = () => import('../components/firma/DisplayFirma.vue');

/** RECLAMACIONES */
const CreateServicio = () => import('../components/servicio/CreateServicio.vue')
const DisplayServicio = () => import('../components/servicio/DisplayServicio.vue')

const CreateTipoTramite = () => import('../components/tipotramite/CreateTipoTramite.vue')
const DisplayTipoTramite = () => import('../components/tipotramite/DisplayTipoTramite.vue')

const CreateTipoRespuesta = () => import('../components/tiporespuesta/CreateTipoRespuesta.vue')
const DisplayTipoRespuesta = () => import('../components/tiporespuesta/DisplayTipoRespuesta.vue')

const CreateTipoCausal = () => import('../components/tipocausal/CreateTipoCausal.vue')
const DisplayTipoCausal = () => import('../components/tipocausal/DisplayTipoCausal.vue')

const CreateCausal = () => import('../components/causal/CreateCausal.vue')
const DisplayCausal = () => import('../components/causal/DisplayCausal.vue')

const CreateTipoNotificacion = () => import('../components/tiponotificacion/CreateTipoNotificacion.vue')
const DisplayTipoNotificacion = () => import('../components/tiponotificacion/DisplayTipoNotificacion.vue')

const CreateFormatoReclamos = () => import('../components/formatoReclamos/CreateFormatoReclamos.vue')
const DisplayFormatoReclamos = () => import('../components/formatoReclamos/DisplayFormatoReclamos.vue')

const FormReclamos = () => import('../components/reports/FormReclamos.vue');
const FormReclamosSspd = () => import('../components/reports/FormReclamosSspd.vue');

/*** END RECLAMACIONES */

/** PQRS CONTRALORIA */
const CreateFormatoPqrs = () => import('../components/formatoPqrs/CreateFormatoPqrs.vue')
const DisplayFormatoPqrs = () => import('../components/formatoPqrs/DisplayFormatoPqrs.vue')

const FormPqrsContraloria = () => import('../components/reports/FormPqrsContraloria.vue');

/** END PQRS */

const CreateCiudad = () => import('../components/ciudad/CreateCiudad.vue')
const DisplayCiudad = () => import('../components/ciudad/DisplayCiudad.vue')

const LogsDcmnt = () => import('../components/reports/LogsDcmnt.vue')
const AttachedFiles = () => import('../components/reports/AttachedFiles.vue')

const FixSizePagesAdjuncts = () => import('../components/doc/FixSizePagesAdjuncts');
const FixOcr = () => import('../components/doc/FixOcr');
const MessageBox = () => import('../components/messageBox');
const AuthFederado = () => import('../components/authFederado');

const CreateReglaEmailGlobal = () => import('../components/reglaemail/CreateReglaEmailGlobal.vue');
const DisplayReglaEmailGlobal = () => import('../components/reglaemail/DisplayReglaEmailGlobal.vue');

const CreateReglaEmail = () => import('../components/reglaemail/CreateReglaEmail.vue');
const DisplayReglaEmail = () => import('../components/reglaemail/DisplayReglaEmail.vue');

const CreateProceso = () => import('../components/proceso/CreateProceso.vue');
const DisplayProceso = () => import('../components/proceso/DisplayProceso.vue');

//const fileManager = () => import('../components/fileManager');

Vue.use(VueRouter)

const routes = [
  // Signin
  {
    path: '/',
    redirect: '/dashboard'
  },

  {
    name: 'Signin',
    path: '/signin',
    component: Signin
  },
  {
    name: 'Ldap',
    path: '/ldap',
    component: Ldap
  },

  //Ver documento de seguimiento
  {
    name: 'Segdoc',
    path: '/segdoc/:id',
    component: Signin
  },

  {
    name: 'Ldap',
    path: '/ldap',
    component: Ldap
  },

  //Terminos y condiciones
  {
    name: 'tyc',
    path: '/tyc',
    component: TerminosYCondiciones
  },

  // Signup
  {
    name: 'Signup',
    path: '/signup',
    component: Signup
  },

  // Success
  {
    name: 'Success',
    path: '/success',
    component: Success
  },

  // EmailCheck
  {
    name: 'EmailCheck',
    path: '/emailcheck/:id',
    component: EmailCheck
  },

  // PwdReset
  {
    name: 'PwdReset',
    path: '/pwdreset',
    component: PwdReset
  },

  // PwdEmail
  {
    name: 'PwdEmail',
    path: '/pwdemail',
    component: PwdEmail
  },

  // PwdChange
  {
    name: 'PwdChange',
    path: '/pwdchange/:token',
    component: PwdChange
  },

  // PwdSuccess
  {
    name: 'PwdSuccess',
    path: '/pwdsuccess',
    component: PwdSuccess
  },

  // PwdNotFound
  {
    name: 'PwdNotFound',
    path: '/pwdnotfound',
    component: PwdNotFound
  },

  // NotFound
  {
    name: 'NotFound',
    path: '/notfound',
    component: NotFound
  },

  // IdleLogout
  {
    name: 'IdleLogout',
    path: '/idlelogout',
    component: IdleLogout
  },

  // Pqrs
  {
    name: 'Pqrs',
    path: '/pqrs',
    component: Pqrs,
    children: [
      {
        name: 'AcceptedPqrs',
        path: 'accepted',
        component: AcceptedPqrs
      },
      {
        name: 'ErrorPqrs',
        path: 'error',
        component: ErrorPqrs
      },
      {
        name: 'FindPqrs',
        path: 'find',
        component: FindPqrs
      },
      {
        name: 'NewPqrs',
        path: 'new',
        component: NewPqrs
      },
      {
        name: 'ResultPqrs',
        path: 'result',
        component: ResultPqrs
      }
    ]
  },

  // Dashboard
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
    children: [
      //Home
      {
        name: 'Home',
        path: 'home',
        component: Home
      },

      //Terminos y condiciones
      {
        name: 'TerminosYCondiciones',
        path: 'terminosycondiciones',
        component: TerminosYCondiciones
      },

      // Documentos
      {
        name: 'DisplayDoc',
        path: 'doc',
        component: DisplayDoc
      },
      {
        name: 'CreateDoc',
        path: 'doc/create',
        component: CreateDoc
      },
      {
        name: 'AdvancedSearchDoc',
        path: 'doc/adsearch',
        component: AdvancedSearchDoc
      },
      {
        name: 'ViewPdf',
        path: 'doc/viewpdf',
        component: ViewPdf
      },
      {
        name: 'DisplayDocNotInTablas',
        path: 'doc/notintablas',
        component: DisplayDocNotInTablas
      },
      {
        name: 'viewHistory',
        path: 'doc/viewhistory',
        component: viewHistory
      },

      // Dependencias
      {
        name: 'DisplayDependencia',
        path: 'dep',
        component: DisplayDependencia
      },
      {
        name: 'CreateDependencia',
        path: 'dep/create',
        component: CreateDependencia
      },

      // Índices
      {
        name: 'DisplayIndice',
        path: 'indice',
        component: DisplayIndice
      },
      {
        name: 'CreateIndice',
        path: 'indice/create',
        component: CreateIndice
      },

      // Series
      {
        name: 'DisplaySerie',
        path: 'serie',
        component: DisplaySerie
      },
      {
        name: 'CreateSerie',
        path: 'serie/create',
        component: CreateSerie
      },

      // Tipos de Archivo
      {
        name: 'DisplayTpoarch',
        path: 'tpoarch',
        component: DisplayTpoarch
      },
      {
        name: 'CreateTpoarch',
        path: 'tpoarch/create',
        component: CreateTpoarch
      },

      // Tipos de Documento-Correspondencia
      {
        name: 'DisplayTpocor',
        path: 'tpocor',
        component: DisplayTpocor
      },
      {
        name: 'CreateTpocor',
        path: 'tpocor/create',
        component: CreateTpocor
      },

      // Compañías
      {
        name: 'DisplayCompania',
        path: 'cmp',
        component: DisplayCompania
      },
      {
        name: 'CreateCompania',
        path: 'cmp/create',
        component: CreateCompania
      },

      // Tipos de Documento
      {
        name: 'DisplayTpodoc',
        path: 'tpodoc',
        component: DisplayTpodoc
      },
      {
        name: 'CreateTpodoc',
        path: 'tpodoc/create',
        component: CreateTpodoc
      },

      // Tipos de Tabla
      {
        name: 'DisplayTpotabla',
        path: 'tpotabla',
        component: DisplayTpotabla
      },
      {
        name: 'CreateTpotabla',
        path: 'tpotabla/create',
        component: CreateTpotabla
      },

      // Estados
      {
        name: 'DisplayEstado',
        path: 'estado',
        component: DisplayEstado
      },
      {
        name: 'CreateEstado',
        path: 'estado/create',
        component: CreateEstado
      },

      // Usuarios
      {
        name: 'DisplayUsuario',
        path: 'usuario',
        component: DisplayUsuario
      },
      {
        name: 'CreateUsuario',
        path: 'usuario/create',
        component: CreateUsuario
      },
      {
        name: 'Profile',
        path: 'usuario/profile',
        component: Profile
      },
      {
        name: 'Security',
        path: 'usuario/security',
        component: Security
      },

      // Usuarios Global
      {
        name: 'DisplayUsuarioGlobal',
        path: 'usuarioglobal',
        component: DisplayUsuarioGlobal
      },
      {
        name: 'CreateUsuarioGlobal',
        path: 'usuarioglobal/create',
        component: CreateUsuarioGlobal
      },

      // Tipos de Usuario
      {
        name: 'DisplayTpousu',
        path: 'tpousu',
        component: DisplayTpousu
      },
      {
        name: 'CreateTpousu',
        path: 'tpousu/create',
        component: CreateTpousu
      },

      // Versiones de Tablas
      {
        name: 'DisplayTablaVersion',
        path: 'tabver',
        component: DisplayTablaVersion
      },
      {
        name: 'CreateTablaVersion',
        path: 'tabver/create',
        component: CreateTablaVersion
      },

      // Tablas
      {
        name: 'DisplayTabla',
        path: 'tabla',
        component: DisplayTabla
      },
      {
        name: 'CreateTabla',
        path: 'tabla/create',
        component: CreateTabla
      },

      // Usuarios por dependencia
      {
        name: 'DisplayUsuxdep',
        path: 'uxd',
        component: DisplayUsuxdep
      },
      {
        name: 'CreateUsuxdep',
        path: 'uxd/create',
        component: CreateUsuxdep
      },

      // Usuarios por serie
      {
        name: 'DisplayUsuxrad',
        path: 'uxr',
        component: DisplayUsuxrad
      },
      {
        name: 'CreateUsuxrad',
        path: 'uxr/create',
        component: CreateUsuxrad
      },
      {
        name: 'CopyUsuxrad',
        path: 'uxr/copy',
        component: CopyUsuxrad
      },

      // Salas
      {
        name: 'DisplaySala',
        path: 'sala',
        component: DisplaySala
      },
      {
        name: 'CreateSala',
        path: 'sala/create',
        component: CreateSala
      },

      // Usuarios por sala
      {
        name: 'DisplayUsuxsal',
        path: 'uxs',
        component: DisplayUsuxsal
      },
      {
        name: 'CreateUsuxsal',
        path: 'uxs/create',
        component: CreateUsuxsal
      },

      // Menú
      {
        name: 'DisplayMenu',
        path: 'menu',
        component: DisplayMenu
      },
      {
        name: 'CreateMenu',
        path: 'menu/create',
        component: CreateMenu
      },

      // Menú por perfil
      {
        name: 'DisplayMenupf',
        path: 'menupf',
        component: DisplayMenupf
      },
      {
        name: 'CreateMenupf',
        path: 'menupf/create',
        component: CreateMenupf
      },

      // Migrar datos
      {
        name: 'MigrarDatos',
        path: 'migrar',
        component: MigrarDatos
      },

      // Exportar datos
      {
        name: 'Export',
        path: 'export',
        component: Export
      },

      // Contadores
      {
        name: 'DisplayCounter',
        path: 'counter',
        component: DisplayCounter
      },
      {
        name: 'CreateCounter',
        path: 'counter/create',
        component: CreateCounter
      },

      // Reporte Control de Entrega
      {
        name: 'FormControlEntrega',
        path: 'report/formentrega',
        component: FormControlEntrega
      },
      {
        name: 'ReportControlEntrega',
        path: 'report/entrega',
        component: ReportControlEntrega
      },

      // Reporte Control de Entrega
      {
        name: 'FormPendienteRespuesta',
        path: 'report/formpendiente',
        component: FormPendienteRespuesta
      },
      {
        name: 'ReportPendienteRespuesta',
        path: 'report/pendiente',
        component: ReportPendienteRespuesta
      },

      // Reporte de Estadística de Correspondencia Radicada
      {
        name: 'FormEstadisticaRadicada',
        path: 'report/formestadrad',
        component: FormEstadisticaRadicada
      },
      {
        name: 'ReportEstadisticaRadicada',
        path: 'report/radicada',
        component: ReportEstadisticaRadicada
      },

      // Reporte de Estadística de Correspondencia Radicada por Usuarios
      {
        name: 'FormEstadisticaRadUsuario',
        path: 'report/formestadradusuario',
        component: FormEstadisticaRadUsuario
      }, {
        name: 'ReportEstadisticaRadUsuario',
        path: 'report/radusuario',
        component: ReportEstadisticaRadUsuario
      },

      // Reporte de Inventario de documentos
      {
        name: 'FormInventarioDocs',
        path: 'report/forminventariodocs',
        component: FormInventarioDocs
      },
      {
        name: 'ReportInventarioDocs',
        path: 'report/inventariodocs',
        component: ReportInventarioDocs
      },

      // Fuid
      {
        name: 'FormFuid',
        path: 'report/formfuid',
        component: FormFuid
      },
      {
        name: 'ReportFuid',
        path: 'report/fuid',
        component: ReportFuid
      },

      // Reporte de Etiqueta de Caja
      {
        name: 'FormEtiquetaCaja',
        path: 'report/formetiquetacaja',
        component: FormEtiquetaCaja
      },
      {
        name: 'ReportEtiquetaCaja',
        path: 'report/etiquetacaja',
        component: ReportEtiquetaCaja
      },

      // Reporte de Contenido de Caja
      {
        name: 'FormContenidoCaja',
        path: 'report/formcontenidocaja',
        component: FormContenidoCaja
      }, {
        name: 'ReportContenidoCaja',
        path: 'report/contenidocaja',
        component: ReportContenidoCaja
      },

      // Reporte de Contenido de Legajo
      {
        name: 'FormContenidoLegajo',
        path: 'report/formcontenidolegajo',
        component: FormContenidoLegajo
      }, {
        name: 'ReportContenidoLegajo',
        path: 'report/contenidolegajo',
        component: ReportContenidoLegajo
      },

      // Reporte de documentos sin archivar
      {
        name: 'FormDocsSinArchivar',
        path: 'report/formdocssinarchivar',
        component: FormDocsSinArchivar
      },
      {
        name: 'ReportDocsSinArchivar',
        path: 'report/docssinarchivar',
        component: ReportDocsSinArchivar
      },

      // Reporte de Transferencia de documentos
      {
        name: 'FormTransferencias',
        path: 'report/formtransferencias',
        component: FormTransferencias
      },
      {
        name: 'ReportTransferencias',
        path: 'report/transferencias',
        component: ReportTransferencias
      },

      // Reporte de Documentos radicados
      {
        name: 'FormDocsRadicados',
        path: 'report/formdocsradicados',
        component: FormDocsRadicados
      },
      {
        name: 'ReportDocsRadicados',
        path: 'report/docsradicados',
        component: ReportDocsRadicados
      },

      // Reporte de Usuario con Seguimientos
      {
        name: 'FormSegDocs',
        path: 'report/formsegdocs',
        component: FormSegDocs
      },
      {
        name: 'ReportSegDocs',
        path: 'report/segdocs',
        component: ReportSegDocs
      },

      // Reporte de Documentos creados por usuario
      {
        name: 'FormDocsRadUsuario',
        path: 'report/formdocsradusuario',
        component: FormDocsRadUsuario
      }, {
        name: 'ReportDocsRadUsuario',
        path: 'report/docsradusuario',
        component: ReportDocsRadUsuario
      },

      // Reporte de Documentos sin archivos adjuntos
      {
        name: 'FormSinAdjuntos',
        path: 'report/formsinadjuntos',
        component: FormSinAdjuntos
      },
      {
        name: 'ReportSinAdjuntos',
        path: 'report/sinadjuntos',
        component: ReportSinAdjuntos
      },

      // Reporte de Adjuntos Faltantes
      {
        name: 'FormAdjuntosFaltantes',
        path: 'report/formadjuntosfaltantes',
        component: FormAdjuntosFaltantes
      },
      {
        name: 'ReportAdjuntosFaltantes',
        path: 'report/adjuntosfaltantes',
        component: ReportAdjuntosFaltantes
      },

      // Reporte de Tamaño de Repositorio
      {
        name: 'FormTamanoRepos',
        path: 'report/formtamanorepos',
        component: FormTamanoRepos
      },
      {
        name: 'ReportTamanoRepos',
        path: 'report/reporttamanorepos',
        component: ReportTamanoRepos
      },

      // Reporte de Pqrs
      {
        name: 'FormPqrs',
        path: 'report/formpqrs',
        component: FormPqrs
      },
      {
        name: 'ReportPqrs',
        path: 'report/reportpqrs',
        component: ReportPqrs
      },

      // Reporte de Solicitudes asignadas
      {
        name: 'FormSolicitudesAsignadas',
        path: 'report/form-sol-asign',
        component: FormSolicitudesAsignadas
      },
      {
        name: 'ReportSolicitudesAsignadas',
        path: 'report/report-sol-asign',
        component: ReportSolicitudesAsignadas
      },

      // Reporte de Estadística de Pqrs
      {
        name: 'FormEstadisticaPqrs',
        path: 'report/formestadpqrs',
        component: FormEstadisticaPqrs
      },
      {
        name: 'ReportEstadisticaPqrs',
        path: 'report/reportestadpqrs',
        component: ReportEstadisticaPqrs
      },

      // Reporte de Estadística de Pqrs Total
      {
        name: 'FormEstadisticaPqrsTotal',
        path: 'report/formestadpqrstotal',
        component: FormEstadisticaPqrsTotal
      },
      {
        name: 'ReportEstadisticaPqrsTotal',
        path: 'report/reportestadpqrstotal',
        component: ReportEstadisticaPqrsTotal
      },

      // Reporte de Estadística Solicitudes por Proceso
      {
        name: 'FormEstadisticaProcesos',
        path: 'report/formestadprocesos',
        component: FormEstadisticaProcesos
      },
      {
        name: 'ReportEstadisticaProcesos',
        path: 'report/reportestadprocesos',
        component: ReportEstadisticaProcesos
      },

      // Reporte de Estadística de Documentos tramitados
      {
        name: 'FormEstadisticaDocsTramitados',
        path: 'report/formestaddocstramitados',
        component: FormEstadisticaDocsTramitados
      },
      {
        name: 'ReportEstadisticaDocsTramitados',
        path: 'report/reportestaddocstramitados',
        component: ReportEstadisticaDocsTramitados
      },

      // Reporte de Tiempos de respuesta
      {
        name: 'FormTiemposRespuesta',
        path: 'report/formtresp',
        component: FormTiemposRespuesta
      },
      {
        name: 'ReportTiemposRespuesta',
        path: 'report/reporttresp',
        component: ReportTiemposRespuesta
      },

      // Unidades de conservación
      {
        name: 'DisplayUconserv',
        path: 'uconserv',
        component: DisplayUconserv
      },
      {
        name: 'CreateUconserv',
        path: 'uconserv/create',
        component: CreateUconserv
      },

      // Usuarios por compañía
      {
        name: 'DisplayUsuxcmp',
        path: 'uxc',
        component: DisplayUsuxcmp
      },
      {
        name: 'CreateUsuxcmp',
        path: 'uxc/create',
        component: CreateUsuxcmp
      },

      // Help
      {
        name: 'Help',
        path: 'help/doc',
        component: Help
      },

      // Support
      {
        name: 'Support',
        path: 'help/support',
        component: Support
      },

      //About
      {
        name: 'About',
        path: '/help/about',
        component: About
      },

      // Tipos de Pqrs
      {
        name: 'DisplayTpopqrs',
        path: 'tpopqrs',
        component: DisplayTpopqrs
      },
      {
        name: 'CreateTpopqrs',
        path: 'tpopqrs/create',
        component: CreateTpopqrs
      },

      // Configuración Compañía
      {
        name: 'Compania',
        path: 'settings/compania',
        component: Compania
      },
      // Configuración Pqrs
      {
        name: 'SettingPqrs',
        path: 'settings/pqrs',
        component: SettingPqrs
      },
      // Configuración Email
      {
        name: 'SettingEmail',
        path: 'settings/email',
        component: SettingEmail
      },
      {
        name: 'AuthFederado',
        path: 'settings/authFederado',
        component: AuthFederado
      },
      // Configuración Opciones
      {
        name: 'SettingOptions',
        path: 'settings/options',
        component: SettingOptions
      },
      // Configuración Backup
      {
        name: 'SettingBackup',
        path: 'settings/backup',
        component: SettingBackup
      },
      // Entidades
      {
        name: 'DisplayEntidad',
        path: 'entidad',
        component: DisplayEntidad
      },
      {
        name: 'CreateEntidad',
        path: 'entidad/create',
        component: CreateEntidad
      },

      // Tipos de identificación
      {
        name: 'DisplayTpoid',
        path: 'tpoid',
        component: DisplayTpoid
      },
      {
        name: 'CreateTpoid',
        path: 'tpoid/create',
        component: CreateTpoid
      },

      // Tipos de solicitante
      {
        name: 'DisplayTposolic',
        path: 'tposolic',
        component: DisplayTposolic
      },
      {
        name: 'CreateTposolic',
        path: 'tposolic/create',
        component: CreateTposolic
      },

      // Medios de respuesta
      {
        name: 'DisplayMedioresp',
        path: 'medioresp',
        component: DisplayMedioresp
      },
      {
        name: 'CreateMedioresp',
        path: 'medioresp/create',
        component: CreateMedioresp
      },

      // Sesiones
      {
        name: 'DisplayLog',
        path: 'log',
        component: DisplayLog
      },
      {
        name: 'CreateLog',
        path: 'log/view',
        component: CreateLog
      },

      // Sesiones Global
      {
        name: 'DisplayLogGlobal',
        path: 'logglobal',
        component: DisplayLogGlobal
      },
      {
        name: 'CreateLogGlobal',
        path: 'logglobal/view',
        component: CreateLogGlobal
      },

      // Actividad de usuario
      {
        name: 'DisplayActivity',
        path: 'activity',
        component: DisplayActivity
      },
      {
        name: 'DetailActivity',
        path: 'activity/detail',
        component: DetailActivity
      },

      // Plantillas
      {
        name: 'DisplayPlantilla',
        path: 'plantilla',
        component: DisplayPlantilla
      },
      {
        name: 'CreatePlantilla',
        path: 'plantilla/create',
        component: CreatePlantilla
      },

      // Buzones de correo
      {
        name: 'DisplayInbox',
        path: 'inbox',
        component: DisplayInbox
      },
      {
        name: 'CreateInbox',
        path: 'inbox/create',
        component: CreateInbox
      },
      // Correo electrónico conectado
      {
        name: 'DisplayInboxUser',
        path: 'inboxuser',
        component: DisplayInboxUser
      },
      {
        name: 'CreateInboxUser',
        path: 'inboxuser/create',
        component: CreateInboxUser
      },

      // Préstamos
      {
        name: 'DisplayPrestamo',
        path: 'prestamo',
        component: DisplayPrestamo
      },
      {
        name: 'CreatePrestamo',
        path: 'prestamo/view',
        component: CreatePrestamo
      },

      // Día no laboral
      {
        name: 'DisplayDianol',
        path: 'dianol',
        component: DisplayDianol
      },
      {
        name: 'CreateDianol',
        path: 'dianol/view',
        component: CreateDianol
      },

      // Día no laboral Global
      {
        name: 'DisplayDianolGlobal',
        path: 'dianolglobal',
        component: DisplayDianolGlobal
      },
      {
        name: 'CreateDianolGlobal',
        path: 'dianolglobal/view',
        component: CreateDianolGlobal
      },

      // Firma
      {
        name: 'DisplayFirma',
        path: 'firma',
        component: DisplayFirma
      },
      {
        name: 'CreateFirma',
        path: 'firma/create',
        component: CreateFirma
      },

      /** RECLAMACIONES */
      // servicios
      {
        name: 'DisplayServicio',
        path: 'Servicio',
        component: DisplayServicio
      },
      {
        name: 'CreateServicio',
        path: 'Servicio/create',
        component: CreateServicio,
      },
      // tipo de tramites
      {
        name: 'DisplayTipoTramite',
        path: 'TipoTramite',
        component: DisplayTipoTramite
      },
      {
        name: 'CreateTipoTramite',
        path: 'TipoTramite/create',
        component: CreateTipoTramite
      },
      // tipo de respuestas
      {
        name: 'DisplayTipoRespuesta',
        path: 'TipoRespuesta',
        component: DisplayTipoRespuesta
      },
      {
        name: 'CreateTipoRespuesta',
        path: 'TipoRespuesta/create',
        component: CreateTipoRespuesta
      },
      // tipo de causales
      {
        name: 'DisplayTipoCausal',
        path: 'TipoCausal',
        component: DisplayTipoCausal
      },
      {
        name: 'CreateTipoCausal',
        path: 'TipoCausal/create',
        component: CreateTipoCausal
      },
      // pendiente causales
      {
        name: 'DisplayCausal',
        path: 'Causal',
        component: DisplayCausal
      },
      {
        name: 'CreateCausal',
        path: 'Causal/create',
        component: CreateCausal
      },
      // tipo de notificaciones
      {
        name: 'DisplayTipoNotificacion',
        path: 'TipoNotificacion',
        component: DisplayTipoNotificacion
      },
      {
        name: 'CreateTipoNotificacion',
        path: 'TipoNotificacion/create',
        component: CreateTipoNotificacion
      },
      // reclamos
      {
        name: 'DisplayFormatoReclamo',
        path: 'FormatoReclamo',
        component: DisplayFormatoReclamos
      },
      {
        name: 'CreateFormatoReclamo',
        path: 'FormatoReclamo/create',
        component: CreateFormatoReclamos
      },

      //Reporte reclamaciones

      {
        name: 'FormReclamos',
        path: 'report/formreclamos',
        component: FormReclamos
      },

      //Reporte reclamaciones Sspd

      {
        name: 'FormReclamosSspd',
        path: 'report/formreclamosSspd',
        component: FormReclamosSspd
      },

      {
        name: 'LogsDcmnt',
        path: 'report/LogsDcmnt',
        component: LogsDcmnt
      },
      {
        name: 'AttachedFiles',
        path: 'report/AttachedFiles',
        component: AttachedFiles
      },

      /** END RECLAMACIONES */

      /** PQRS */

      // PQRS
      {
        name: 'DisplayFormatoPqrs',
        path: 'FormatoPqrs',
        component: DisplayFormatoPqrs
      },
      {
        name: 'CreateFormatoPqrs',
        path: 'FormatoPqrs/create',
        component: CreateFormatoPqrs
      },

      //Reporte Pqrs Contraloría

      {
        name: 'FormPqrsContraloria',
        path: 'report/formpqrscontraloria',
        component: FormPqrsContraloria
      },

      /** END PQRS */
      // Ciudades
      {
        name: 'DisplayCiudad',
        path: 'Ciudad',
        component: DisplayCiudad
      },
      {
        name: 'CreateCiudad',
        path: 'ciudad/create',
        component: CreateCiudad
      },

      {
        name: 'FixSizePagesAdjuncts',
        path: 'FixSizePagesAdjuncts',
        component: FixSizePagesAdjuncts
      },
      {
        name: 'FixOcr',
        path: 'FixOcr',
        component: FixOcr
      },
      {
        name: 'MessageBox',
        path: 'MessageBox',
        component: MessageBox
      },

      // Regla Email
      {
        name: 'DisplayReglaEmail',
        path: 'reglaemail',
        component: DisplayReglaEmail
      },
      {
        name: 'CreateReglaEmail',
        path: 'reglaemail/view',
        component: CreateReglaEmail
      },

      // Regla Email Global
      {
        name: 'DisplayReglaEmailGlobal',
        path: 'reglaemailglobal',
        component: DisplayReglaEmailGlobal
      },
      {
        name: 'CreateReglaEmailGlobal',
        path: 'reglaemailglobal/view',
        component: CreateReglaEmailGlobal
      },

      // Procesos
      {
        name: 'DisplayProceso',
        path: 'proceso',
        component: DisplayProceso
      },
      {
        name: 'CreateProceso',
        path: 'proceso/create',
        component: CreateProceso
      },

      /*
      {
        name: 'fileManager',
        path: 'fileManager',
        component: fileManager
      },
      */
    ]
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 10 }
    }
  }
})

export default router
