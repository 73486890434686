<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Tipos de Pqrs</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-10">
            <div class="form-group row">
              <form v-on:submit.prevent="searchItems(keywords)">
                <div class="input-group">
                  <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-outline-primary">Buscar</button>
                  </div>
                </div>                
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <button v-on:click="$router.push({ name: 'CreateTpopqrs', params: {add: true} });" class="btn btn-primary"><i data-feather="plus-circle"></i>Crear Tipo de Pqrs</button>
            </div>
            <br>
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="list-doc" role="tabpanel" aria-labelledby="list-doc-list">
                <div class="table-responsive">
                  <table class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <td></td>
                        <td>Código</td>
                        <td>Nombre</td>
                        <td>Tiempo respuesta (días)</td>
                        <td>Inactiva</td>
                        <td class="text-center"></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in items">
                        <td class="text-center">
                          <button v-on:click="editItem(item._id)" class="btn btn-primary">Editar</button>                                              
                        </td>
                        <td>{{ item.tpq_cdgo }}</td>
                        <td>{{ item.tpq_nmbre }}</td>
                        <td>{{ item.tpq_tres }}</td>
                        <td>{{ item.tpq_inactiva | formatBoolean }}</td>
                        <td class="text-center">
                          <button class="btn btn-danger" v-on:click="deleteItem(item._id, index)">
                            <i data-feather="delete"></i>Eliminar
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="list-doc-archivo" role="tabpanel" aria-labelledby="list-archivo-list">...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import { formatBoolean } from './../../plugins/filters';

export default {
  components: {
    //VIcon
  },
  data(){
    return{
      items: [],
      keywords:'',
      search: {},
      filter: false, // Items are filtered
    }
  },
  computed: {
    showSearch(){
      if (this.filter){
        return 'block';
      } else{
        return 'none';
      }
    }
  },
  filters: {
    formatBoolean
  },
  mounted: function() {
    feather.replace();
  },
  created: function()
  {
    this.fetchItems();
  },
  methods: {
    fetchItems()
    {
      let uri = '/tpopqrs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.items = response.data;
      });
    },
    deleteItem(id, index)
    {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        let uri = '/tpopqrs/delete/'+id;
        this.axios.get(uri)
        .then(res => {
          this.items.splice(index, 1);
        })
        .catch(err => console.log(err));
      }
      return;
    },
    searchItems(key){
      //alert(key);
      if (key != ''){
        this.message = 'Buscando tipos de pqrs';
        let uri = '/tpopqrs/search';
        this.axios.post(uri, { cmp_id: String(this.$store.state.company), key: key })
        .then(response => {
          console.log(response);
          this.items = response.data;
          this.filter = true;
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los tipos de pqrs' + err;
        });
      }
    },
    closeSearch(){
      this.filter = false;
      this.keywords = '';
      this.fetchItems();
    },
    newSearch () {
      this.search = {};
    },
    createItem(){
      this.$router.push({ name: 'CreateTpopqrs', params: {add: true} });
    },
    editItem(id){
      this.$router.push({ name: 'CreateTpopqrs', params: {id: id, add: false} });
    }
  }
}
</script>