<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Formato Único de Inventario Documental FUID</h1>
        </div>
        <br>
        <form v-on:submit.prevent="searchItems(item)">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Sala (*):</label>
                <select v-model="item.sal_id" ref="sal_id" v-on:change="setSala" class="form-control col-md-8" required>
                  <option v-for="i in salas" v-bind:value="i._id">
                    {{ i.sal_nmbre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Buscar palabras clave:</label>
                <input type="text" v-model="item.text" class="form-control col-md-8">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Estante :</label>
                <select v-model="item.est_id" ref="est_id" class="form-control col-md-8">
                  <option v-for="i in estantes" v-bind:value="i._id">
                    {{ i.est_cdgo }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Fecha inicial (desde):</label>
                <input type="date" class="form-control col-md-8" v-model="item.dcm_fchaini">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Fecha final (hasta):</label>
                <input type="date" class="form-control col-md-8" v-model="item.dcm_fchafin">
              </div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-6">
              <button type="submit" class="btn btn-primary">Consultar</button>
            </div>
            <div class="col-6">
              <button type="text" class="btn btn-secondary" v-on:click="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';

  export default {
    data(){
      return{
        item: {},
        message: '',
        salas: [],
        estantes: [],
        debug: ''
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      this.item = this.$route.params.query || {};
      this.fetchSala();
    },
    mounted: function(){

    },
    methods: {
      fetchSala()
      {
        let uri = '/salas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            if (a.sal_nmbre > b.sal_nmbre) { return 1; };
            if (a.sal_nmbre < b.sal_nmbre) { return -1; };
            return 0;
          });
          this.salas = list;
        });
      },
      setSala(e){
        var sala = e.target.value;
        let uri = '/estantes/sala';
        this.axios.post(uri, { cmp_id: this.$store.state.company, sal_id: sala }).then((response) => {
          this.estantes = response.data;
        })
        .catch((err) => {
          alert('No existen estantes relacionados con esta sala.')
        });
      },
      searchItems(q){
        //alert('Consultar');
        if (q.dcm_fchaini && !q.dcm_fchafin || !q.dcm_fchaini && q.dcm_fchafin) {
          alert('Por favor seleccione ambas fechas.');
          return;
        }
        if (q.dcm_fchaini > q.dcm_fchafin) {
          alert('La fecha final debe ser mayor que la inicial.');
          return;
        }
        var p = {};
        p.query = q;
        p.sal_nmbre = this.$refs.sal_id.options[this.$refs.sal_id.selectedIndex].text;
        this.$router.replace({ name: 'ReportFuid', params: p });
      },
      cancel(){
        this.$router.replace({ name: 'Home' });
      }

    } //END METHODS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
