<template>
  <section v-if="login" class="fdb-block" style="background-image: url(/imgs/hero/blue.svg);">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-7 col-xl-5 text-center">
          <div class="fdb-box">
            <div class="row" v-if="$store.state.logo">
              <div class="col" style="width:200px; max-height:200px;">
                <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%;" />
              </div>
            </div>
            <div class="row" v-else-if="companyname && !$store.state.logo">
              <div class="col">
                <h2><b>{{ companyname }}</b></h2>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col">
                <h1><b>¡Bienvenido!</b></h1>
              </div>
            </div>
            <div class="row">
              <div class="col" style="width:100px; max-height:100px;">
                <img src="/images/siged2.png" style="max-width:100%; max-height:100%;" />
              </div>
              <!--
                          <h1 class="">Iniciar sesión</h1>
                          -->
            </div>
            <br>
            <form v-on:submit.prevent="signin()">
              <div class="row">
                <div class="col">
                  <input type="text" v-model="item.email" class="form-control" placeholder="Usuario o correo electrónico"
                    required autofocus @focus="submitFormLogin">
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input type="password" v-model="item.password" class="form-control mb-1" placeholder="Contraseña"
                    required @focus="submitFormLogin">
                  <p v-if="app" class="text-right"><a href="#" v-on:click="recuperarContrasena()">Recuperar Contraseña</a>
                  </p>
                </div>
              </div>
              <div v-if="isRecaptchaVerified">

                <div class="row">
                  <div class="col">
                    <button class="btn btn-outline-primary" 
                      type="submit">Iniciar sesión</button>
                  </div>
                </div>

                <div v-if="app" class="row">
                  <div class="col">
                    <p class="text-center">¿Nuevo en Siged? <a href="#" v-on:click="mostrarPantallaRegistro()">Regístrese</a>
                    </p>
                  </div>
                </div>

                <div class="or-divider">o</div>

                <div class="row d-flex" style="justify-content: center;">
                  <button class="btn v-btn--outlined provider d-flex" style="align-items: center;" type="button"
                    @click="getConnection('microsoft')">
                    <div style="margin-left: -17px;">
                      <img alt="" :src="logo_microsoft" width="" height="" />
                    </div>
                    <span class="w-100 d-flex justify-content-center">Continuar con Microsoft</span>
                  </button>
                </div>

                <div class="row d-flex mt-4" style="justify-content: center;">
                  <button class="btn v-btn--outlined provider d-flex" style="align-items: center;" type="button"
                    @click="getConnection('google')">
                    <div style="margin-left: -17px;">
                      <img alt="" :src="logo_google" width="" height="" />
                    </div>
                    <span class="w-100 d-flex justify-content-center">Continuar con Google</span>
                  </button>
                </div>
                <!--
                <div class="row d-flex mt-4" style="justify-content: center;">
                  <button class="btn v-btn--outlined provider d-flex" style="align-items: center;" type="button"
                    @click="getConnection('ldap')">
                    <div style="margin-left: -17px;">
                      <img alt="" :src="logo_ldap" width="38rem" height="" />
                    </div>
                    <span class="w-100 d-flex justify-content-center">Continuar con Directorio Activo</span>
                  </button>
                </div>
                -->
              </div>
              <div v-if="!isRecaptchaVerified">
                <v-progress-circular
                  color="blue"
                  indeterminate
                ></v-progress-circular>
              </div>
            </form>
            <div v-if="message" class="row">
              <div class="col">
                <div class="alert alert-danger" role="alert">
                  {{ message }}
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <p><a href="http://www.archivosysistemas.com" target="_blank">Archivos y Sistemas AYS</a> - Copyright {{
                  new Date().getFullYear() }}
                  - <router-link :to="{ name: 'tyc' }">Términos y condiciones</router-link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import toastr from 'toastr';
import Bowser from "bowser";
import {
  getConnectionMicrosoft,
  getConnectionGoogle,
  getConnectionLdap
} from "../../plugins/passport-provider"

export default {
  data() {
    return {
      add: true, //Create a new document
      close: true, // Close the page and return to list
      title: 'Iniciar sesión',
      item: {},
      message: '',
      buttontext: '',
      companyname: '',
      content: '<h1>Some initial content</h1>',
      login: false,
      app: false,
      logo_microsoft: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAABnRSTlMAAAAAAABupgeRAAAAWklEQVR4AWP4FKBEJPrip8wABrV7GOp3EYtGhgWjFoxaMGrBqAXf/OSIRD995CEWVO9hqN1FLBoOYNlnotF3iI7/O4lHQOVL3hONPo9agB2NWjBqwagFoxYAAB2zMZ7bQW2tAAAAAElFTkSuQmCC",
      logo_google: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAjVBMVEVHcEz////9/f38/Pz////4+fn9/f38/P3v8PH19vb///////87q1n8wAnrSz5HifXqOijsUUMfpUYvfvQwqVhDhvRnm/bg7ur74eDykIrznpj609DI5c/uZUj/78rG2PyzzPKJyJj5zMn/++37xSf81XhqsFCa0KV1v4f0kCGvtTCCyJyXt/hyofZDj9Yia4J9AAAAC3RSTlMAojhxnKGw/RkrfoV/rhcAAAFQSURBVDiNlVPZYoMgENQYRCIgclRNNKlpm/T+/88rl3jmofPEMuPu7LpE0X+wO8RpGh9222wW5wFxtuYntJUsaATzBSCaFV/SBhMryF9dm46xrjn5cMzh8p9KRsuypJSVTgLn/o6stKAa7Gqv9r6/kadMQ/NHX8R1u7f5Dc+aGsK6CbxPYY+dLk9rd12PnYQWz6SjbEJMW03M4Y2Qj2ZjFslg4ZUQcrZXTwMug4nUHDRPXqxAFBpKKfFsovSBwGiCYFFCGKiiEKHEYPLza/T2owpRDSaRa/MmeTXwla4j7AmFQd0kxtIrYKEK9R0G5Uy0HGPM+wrC6i41L+A4avez7kYhuYbO9ess+p8VARv0RuEgue0xB/OFaaX5GEvJsTMTFsYX0ZJ3XQP3rQ8nu7+5tLO1ztZrv3w7YM6DaIUMhCwQbDw9+z4SkKYgQdvsA/wBrYgleUhdXncAAAAASUVORK5CYII=",
      logo_ldap: "https://foroalfa.org/imagenes/ilustraciones/1121.jpg",
      sitekey: '6Ld5X4MqAAAAAFENxugwckCO0xOgxBPlRvFBwh8F', 
      recaptchaToken: null,
      recaptchaWidgetId: null,
      CargandoReCAPTCHA: true,
      loadRecaptcha: false,
      isRecaptchaVerified: false,
      cargarnuevamente: false,
    }
  },
  computed: {
    company() {
      return this.$store.state.company
    },
    companydata() {
      return this.$store.state.companydata
    },
    cancelButtonVisibility() {
      if (this.addInd) {
        return 'visibility:hidden'
      } else {
        return 'visibility:visible'
      }
    }
  },
  created: function () {
    if (this.$route.query.action) {
      switch (this.$route.query.action) {
        case 'pqrs': //Pqrs
          this.$router.replace({ name: 'Pqrs' });
          break;
        case 'rsp': //Find Pqrs
          this.$router.replace({ name: 'Pqrs', params: { action: 'rsp' } });
          break;
        default:
          break;
      }
    } else {
      this.$store.commit('setIsAuth', false);
      this.$store.commit("setLogo", null);
      //console.log(this.$store.state);

      // SD
      
      var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
      var urlParts = regexParse.exec(window.location.hostname);
      if (!urlParts) {
        this.$router.replace({ name: 'NotFound' });
        return;
      }

      var sd = window.location.hostname.replace(urlParts[0], '').slice(0, -1);
      
      // END SD

      //var sd = 'siged';

      if (sd) {
        if (sd == 'app') {
          this.login = true;
          this.app = true;
        } else {
          let uri = '/cmps/sbs';
          this.axios.post(uri, { sd: sd })
            .then((response) => {
              this.login = true;
              this.$store.commit('setCompanyData', response.data);
              this.$store.commit('setCompany', response.data._id);
              this.item.company = response.data._id;
              this.companyname = response.data.cmp_nmbre;

              /** LOGO */
              if (response.data.cmp_logo) {
                this.axios({
                  url: "/api/logo/download",
                  method: "POST",
                  data: { company: this.item.company, logo: response.data.cmp_logo },
                  responseType: "blob"
                }).then((response) => {
                  this.$store.commit("setLogo", window.URL.createObjectURL(new Blob([response.data])));
                }).catch((err) => {

                });
              }
              /** END LOGO */
            }).catch((err) => {
              this.$router.replace({ name: 'NotFound' });
              //this.message = err.response.data;
            });
        }
      } else {
        this.login = true;
      }
    }
  },
  mounted: function () {
    this.loadRecapchaScript(() => {
      this.submitFormLogin();
    });
    
  },
  beforeRouterEnter(to, from, next){
    next(vm => {
      if (vm.cargarnuevamente) {
        vm.submitFormLogin();
      } else {
        vm.loadRecapchaScript(() => {
          vm.submitFormLogin();
        })
      }
    })
  },
  methods: {
    loadRecapchaScript(){
      if (document.querySelector('recaptcha-script')) return;
      const script = document.createElement('script');
      script.id = 'recaptcha-script';
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.sitekey}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.cargarnuevamente = true;
      };
      document.head.appendChild(script);
    },
    checkGrecaptcha() {
      const tryRender = () => {
        if (window.grecaptcha && window.grecaptcha.enterprise && typeof window.grecaptcha.enterprise.render === 'function') {
          this.renderRecaptcha();
        } else {
          setTimeout(tryRender, 800);
        }
      };
      tryRender();
      this.loadRecaptcha = true;
    }, 
    renderRecaptcha() {
      grecaptcha.enterprise.ready(async () => {
          try {
            const token = await grecaptcha.enterprise.execute(this.sitekey, { action: 'LOGIN' });
            this.onVerify(token);  
          } catch (error) {
            console.error("Error al ejecutar reCAPTCHA:", error);
          }
      });
    },
    onVerify(token) {
      this.recaptchaToken = token;
      this.startTokenExpirationTimer();
    },
    startTokenExpirationTimer() {
      if (this.tokenTimer) clearTimeout(this.tokenTimer); 
      this.tokenTimer = setTimeout(() => {
        this.recaptchaToken = null;  
      }, 300 * 1000);
    },
    async DeleteRecaptchaScript() {
      const nodeBadge = document.querySelector('.grecaptcha-badge');
      if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
      }
      
      const script = document.querySelector('recaptcha-script');
      if (script) {
        script.remove();
      }

    },
    async submitFormLogin() {
      await this.checkGrecaptcha();
      if (this.recaptchaToken){
        return;
      }
      while (!this.recaptchaToken) {
        await new Promise(resolve => setTimeout(resolve, 100)); 
      }
      const response = await this.axios.post('/verificar-reCAPTCHA-Login', {
        recaptchaToken: this.recaptchaToken
      });
      
      if (response.data.success) {
        this.isRecaptchaVerified = true;
      } else {
        console.error('Error verificando el reCAPTCHA:', response.data.message);
      }
    },
    async getConnection(provider) {
      let data = {}
      switch (provider) {
        case "microsoft":
          data = await getConnectionMicrosoft()
          break;
        case "google":
          data = await getConnectionGoogle()
          break;
        case "ldap":
          data = await getConnectionLdap()
          break;
      }

      this.signinProvider(data)
    },
    signinProvider({ id, accessToken }) {
      let uri = '/usuarios/signin-provider';
      this.axios.post(uri, { id, accessToken })
        .then(response => {
          this.onSignin(response)
        }).catch(error => {
          console.log(error);
          this.$store.commit('setIsAuth', false);
          this.message = error.response.data.msg;
        })
    },
    signin() {
      if (!this.isRecaptchaVerified) {
        this.submitFormLogin();
        alert('Espera a que se valide el recaptcha');
        return;
      }
      let uri = '/usuarios/signin3';
      this.axios.post(uri, this.item)
        .then(response => {
          this.onSignin(response)
          //this.fetchMenupf();
        })
        .catch(err => {
          console.log(err);
          this.$store.commit('setIsAuth', false);
          this.message = err.response.data.msg;
        });
    },
    async onSignin(response) {
      if (response.data.companydata) {
        this.$store.commit('setCompanyData', response.data.companydata);
        this.$store.commit('setCompany', response.data.companydata._id);
        this.companyname = response.data.companydata.cmp_nmbre;
      }

      this.$store.commit('setUserdata', response.data);
      this.$store.commit('setUser', response.data.id);
      this.username = response.data.name;
      var token = response.data.token;

      var log = {};
      log.cmp_id = String(this.$store.state.company);
      log.usu_id = String(this.$store.state.user);
      const browser = Bowser.getParser(window.navigator.userAgent);
      log.log_browser = JSON.stringify(browser.getBrowser());
      log.log_os = JSON.stringify(browser.getOS());
      log.log_platform = JSON.stringify(browser.getPlatform());

      try {
        let uri = '/logs/add';

        var resp = await this.axios.post(uri, log);
        sessionStorage.setItem('jwtToken', token)
        this.$store.commit('setSessionId', resp.data.id);
        this.$store.commit('setIsAuth', true);
        this.message = 'Sesión iniciada';
        let r = await this.DeleteRecaptchaScript();

        if (this.$route.params.id) {
          this.$router.replace({ name: 'Dashboard', params: { id: this.$route.params.id } });
        } else {
          this.$router.replace({ name: 'Dashboard' });
        }        
      } catch (err) {
        this.message = 'Error al iniciar la sesión.';
      }
    },
    recuperarContrasena() {
      this.$router.replace({ name: 'PwdReset' });
    },
    mostrarPantallaRegistro() {
      this.$router.replace({ name: 'Signup' });
    }
  } // END METHODS
}
</script>
<style>
.warning {
  color: #ff0000;
}

.app-bg {
  background: #BBDEFB;
}

.provider {
  width: 95%;
  border: 1px solid #ccc;
}

.provider:hover {
  background: #BBDEFB;
}

.or-divider {
  position: relative;
  margin: 0px 0 25px 0;
  text-align: center;
}

.or-divider:before,
.or-divider:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #ccc;
}

.or-divider:before {
  left: 0;
}

.or-divider:after {
  right: 0;
}

.button-container {
  text-align: center;
}
.btn-focus-active{
  background-color: #329ef7 !important;
  color: #fff !important;
}
.btn-focus-active:hover {
  background-color: #329ef7 !important;
  color: #fff !important;
}
</style>