<template>
  <div class="col-md-12 mx-auto">
    <div class="card shadow-lg rounded">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Compañías</h1>
        </div>
        <br>
        <div class="form-group row">
          <form v-on:submit.prevent="searchItems(keywords)">
            <div class="input-group">
              <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
              <div class="input-group-append">
                <button type="submit" class="btn btn-outline-primary">Buscar</button>
              </div>
            </div>                
          </form>
        </div>
        <div class="row">
          <button class="btn btn-primary" v-on:click="$router.push({ name: 'CreateCompania', params: {add: true} })"><i data-feather="plus-circle"></i> Crear compañía</button>
        </div>
        <br/>
        <div v-if="filter" class="alert alert-primary" role="alert">
          {{ keywords }}
          <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table class="table table-responsive table-hover table-bordered">
              <thead>
                <tr>
                  <td>Id</td>
                  <td>Número</td>
                  <td>Nombre</td>
                  <td>Ciudad</td>
                  <td>Dirección</td>
                  <td>Teléfono</td>
                  <td>Subdominio</td>
                  <td>Activar Firmas</td>
                  <td>Activar Ocr</td>
                  <td>Activar Correo electrónico</td>
                  <td>Activar Procesos</td>
                  <td>Activar Reclamaciones</td>
                  <td>Activar Pqrs Contraloría</td>
                  <td>Fecha Registro</td>
                  <td>Inactiva</td>
                  <td>Descripción</td>
                  <td>Acciones</td>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in items" v-bind:class="{ inactivo: item.cmp_inactiva }">
                  <td>
                    <router-link :to="{ name: 'CreateCompania', params: {id: item._id, add: false} }" class="btn btn-link">
                      {{ item._id }}
                    </router-link>
                  </td>
                  <td>{{ item.cmp_nmro }}</td>
                  <td>{{ item.cmp_nmbre }}</td>
                  <td>{{ item.cmp_ciudad }}</td>
                  <td>{{ item.cmp_direccion }}</td>
                  <td>{{ item.cmp_telefono }}</td>
                  <td>{{ item.cmp_subdominio }}</td>
                  <td>{{ item.cmp_activar_firmas | formatBoolean }}</td>
                  <td>{{ item.cmp_activar_ocr | formatBoolean }}</td>
                  <td>{{ item.cmp_activar_email | formatBoolean }}</td>
                  <td>{{ item.cmp_activar_procesos | formatBoolean }}</td>
                  <td>{{ item.cmp_activar_reclamaciones | formatBoolean }}</td>
                  <td>{{ item.cmp_activar_pqrs | formatBoolean }}</td>
                  <td>{{ item.cmp_fechareg | formatDate }}</td>
                  <td>{{ item.cmp_inactiva | formatBoolean }}</td>
                  <td>{{ item.cmp_descripcion }}</td>
                  <td>
                    <button v-on:click="$router.push({ name: 'CreateCompania', params: { id: item._id, add: false } })" class="btn btn-primary">Editar</button>

                    <button class="btn btn-danger" v-on:click="deleteItem(item._id, index)">
                      Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import { formatBoolean, formatDate } from './../../plugins/filters';

export default {
  components: {
    //VIcon
  },
  data(){
    return{
      items: [],
      keywords:'',
      filter: false, // Items are filtered
    }
  },
  filters: {
    formatBoolean, formatDate
  },
  mounted: function(){
    feather.replace();
  },
  created: function(){
    this.fetchItems();
  },
  methods: {
    fetchItems()
    {
      let uri = '/cmps';
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.get(uri).then((response) => {
        this.items = response.data;
      });
    },
    deleteItem(id, index)
    {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        let uri = '/cmps/delete/'+id;
        this.axios.get(uri)
        .then(res => {
          this.items.splice(index, 1);
        })
        .catch(err => console.log(err));
      }
      return;
    },
    searchItems(keywords){
      //alert(keywords);
      if (!keywords == ''){
        this.message = 'Buscando compañías';
        let uri = '/cmps/search';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, { key: keywords })
        .then(response => {
          console.log(response);
          this.items = response.data;
          this.filter = true;
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar las compañias' + err;
        });
      }
    },
    closeSearch(){
      this.filter = false;
      this.keywords = '';
      this.fetchItems();
    }
  }
}
</script>

<style>
.inactivo {
  /*
  background-color: #FFEAE8;
  */
  color: lightcoral;
  text-decoration: line-through;
}
</style>